/*
 * Custom
 */
$( document ).ready(function() {



    // Slider testimonials

    var swiper1 = new Swiper('.slider-testimonials', {
        slidesPerView: 2,
        allowTouchMove: true,
        navigation: {
            nextEl: '.swiper-button-next-testim',
            prevEl: '.swiper-button-prev-testim',
        },
        breakpoints: {
            992: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
            }
        },
    });

    // Slider Cloud

    var swiper2 = new Swiper('.slider-cloud', {
        slidesPerView: 1,
        allowTouchMove: true,
        navigation: {
            nextEl: '.cloud-next',
            prevEl: '.cloud-prev',
        },
        breakpoints: {
            992: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
            }
        },
    });

    // Slider Reach

    var swiper3 = new Swiper('.swiper-reach', {
        allowTouchMove: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    // Slider Compatibility

    var swiper4 = new Swiper('.swiper-compatibility', {
        allowTouchMove: true,
        slidesPerView: 2,
        navigation: {
            nextEl: '.button-next',
            prevEl: '.button-prev',
        },
    });

    // Courses testimonials

    var swiper5 = new Swiper('.courses-slider', {
        allowTouchMove: true,
        slidesPerView: 2,
        navigation: {
            nextEl: '.swiper-button-next-testim',
            prevEl: '.swiper-button-prev-testim',
        },
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
        loop: true,
        breakpoints: {
            992: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }
        },
    });



    // Show more/less table

    var table = $('.events_table .container .table');

    $(".table-more").click(function () {
        if($(table).hasClass("more")) {
            $(this).find('span').text("See more");
        } else {
            $(this).find('span').text("See less");
        }

        $(table).toggleClass("more");
    });

    // Pricing switcher

    $('#switcher').on('click', function () {
       $('.monthly').toggleClass('active');
       $('.yearly').toggleClass('active');
    });

    // Pricing tabs mobile

    $('.pricing-tabs a').click(function(e){
        $('.switcher').removeClass('active');
        $('.pricing-tabs a').removeClass('active');
        $(this).addClass('active');
        var anchor = $(this).attr('href').replace("#",".");
        $(anchor).addClass('active').siblings().removeClass('active');
    });

    $('.pricing-tabs a:nth-of-type(2)').on('click', function () {
       $('.switcher').addClass('active');
    });
    $('.pricing-tabs a:nth-of-type(3)').on('click', function () {
        $('.switcher').addClass('active');
    });

    // Mobile hamburger

    $('.burger').on('click', function () {
       $('.nav_menu').toggleClass('active');
    });

    // Contact btn modal

    $('.btn-contact').on('click', function () {
       $('.quote').addClass('active');
    });

    $('.quote .close a').on('click', function () {
        $('.quote').removeClass('active');
    });

    // AOS

    AOS.init();

});


    // Accordion

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}